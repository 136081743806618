import React, { FC } from 'react';

import { Wrapper } from './styles';
import { ContentfulSection } from '../types.d';

export type DividerSection = ContentfulSection;

/**
 * Divider section
 */
const Divider: FC<DividerSection> = () => <Wrapper />;

export default Divider;
